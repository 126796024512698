import { CandidateRouteInfo } from 'feature-router'

/**
 * Site feature config is calculated in SSR when creating the `viewerModel`
 * The config is available to your feature by injecting `named(SiteFeatureConfigSymbol, name)`
 */
export type ProtectedPagesSiteConfig = {
	passwordProtected: { [pageId: string]: string }
}

export type ProtectedPageMasterPageConfig = {
	pagesSecurity: {
		[pageId: string]: {
			requireLogin: boolean
			passwordDigest: string
		}
	}
}

export type PagesMap = { [pageId: string]: string }
export type ProtectedPagesState = {
	pagesMap: PagesMap
	loginAndNavigate: (routerInfo: Partial<CandidateRouteInfo>, loginType: LoginTypes) => Promise<boolean>
}
export enum LoginTypes {
	SM = 'SM',
	Pass = 'PASS',
	NONE = 'NONE',
}
